// Known bugs: using keys for repeated list won't changed when refreshed
// - we also need to support bind into array/object index/key if specified
//
// Note: using .apply can be more faster than ...spread

var RE_Assign = false;
var RE_ProcessIndex;
const repeatedListBinding = internal.model.repeatedListBinding = function(elements, modelRef, namespace, modelKeysRegex){
	// modelKeysRegex can be a template too
	let element, script;

	for (let i = 0; i < elements.length; i++) {
		element = elements[i];

		if(element.getAttribute === void 0){
			script = element.rule;
			element = element.el;
			RE_ProcessIndex = i;
		}
		else{
			// ToDo: find the culprit why we need to check this
			if(!element.hasAttribute('sf-each'))
				continue;

			script = element.getAttribute('sf-each');
			element.removeAttribute('sf-each');
		}

		element.sf$componentIgnore = true;

		let pattern = script.match(sfRegex.repeatedList);
		if(pattern === null){
			console.error("'", script, "' should match the pattern like `key,val in list`");
			continue;
		}
		pattern = pattern.slice(1);

		if(pattern[0].includes(','))
			pattern[0] = pattern[0].split(' ').join('').split(',');

		let target = modelRef[pattern[1]];
		if(target === void 0){
			const isDeep = parsePropertyPath(pattern[1]);
			if(isDeep.length !== 1){
				pattern[1] = isDeep;
				target = deepProperty(modelRef, isDeep);

				// Cache deep
				if(modelRef.sf$internal)
					modelRef.sf$internal.deepBinding[isDeep.slice(0, -1).join('%$')] = true;
			}

			if(target === void 0)
				modelRef[pattern[1]] = target = [];
		}
		else{
			// Enable element binding
			if(modelRef.sf$bindedKey === void 0)
				initBindingInformation(modelRef);

			modelRef.sf$bindedKey[pattern[1]] = true;
		}

		const { constructor } = target;
		let proto;
		if(constructor === Array || constructor === RepeatedList)
			proto = RepeatedList;
		else if(constructor === Object || constructor === RepeatedProperty)
			proto = RepeatedProperty;
		else if(constructor === Map || constructor === RepeatedMap)
			proto = RepeatedMap;
		else if(constructor === Set || constructor === RepeatedSet)
			proto = RepeatedSet;
		else if(constructor === WeakSet || constructor === WeakMap){
			console.error(pattern[1], target, "WeakMap or WeakSet is not supported");
			continue;
		}
		else{
			console.error(pattern[1], target, "should be an array or object but got", constructor);
			continue;
		}

		// Parse the sf-each="rule in pattern"
		pattern = parsePatternRule(modelRef, pattern, proto);
		proto.construct(modelRef, element, pattern, element.parentNode, namespace, modelKeysRegex);
	}
}

function parsePatternRule(modelRef, pattern, proto){
	let prop = pattern[1];
	const that = prop.constructor === String ? modelRef[prop] : deepProperty(modelRef, prop);

	var firstInit;
	if(that.constructor !== proto){
		Object.setPrototypeOf(that, proto.prototype);
		firstInit = true;
	}

	// that = the list object
	// target = model or the parent object of the list
	// prop = property name

	if(prop.constructor !== Array)
		return {that, target: modelRef, prop, pattern, firstInit};

	return {
		that,
		target: deepProperty(modelRef, prop.slice(0, -1)),
		prop: prop[prop.length-1],
		pattern,
		firstInit
	};
}

function prepareRepeated(modelRef, element, rule, parentNode, namespace, modelKeysRegex){
	const {target, prop, pattern} = rule;
	let callback = target[`on$${prop}`] || {};

	const compTemplate = (namespace || sf.component).registered[element.tagName.toLowerCase()];
	if(compTemplate !== void 0 && compTemplate[3] === false && element.childNodes.length !== 0)
		compTemplate[3] = element;

	const isComponent = compTemplate !== void 0 ? compTemplate[1] : false;
	const EM = new ElementManipulator();

	if(this.$EM === void 0){
		hiddenProperty(this, '$EM', EM, true);
		Object.defineProperty(target, `on$${prop}`, {
			configurable: true,
			get:()=> callback,
			set:(val)=> Object.assign(callback, val)
		});
	}
	else if(this.$EM.constructor === ElementManipulatorProxy)
		this.$EM.list.push(EM);
	else{
		const newList = [this.$EM, EM];
		this.$EM = new ElementManipulatorProxy();
		this.$EM.list = newList;
	}

	let mask = pattern[0], uniqPattern;
	if(mask.constructor === Array){
		uniqPattern = mask[0];
		mask = mask.pop();
	}

	EM.asScope = void 0;

	let template, originalAddr;
	if(!isComponent){
		// Get reference for debugging
		processingElement = element;

		if(modelKeysRegex.specialElement !== void 0)
			originalAddr = modelKeysRegex.specialElement.repeat[RE_ProcessIndex];

		if(!originalAddr || originalAddr.template === void 0){
			let container;
			if(element.namespaceURI === 'http://www.w3.org/2000/svg' && (element.constructor._ref || element.constructor) !== SVGSVGElement)
				container = 'svg';

			template = self.extractPreprocess(element, mask, modelRef, container, modelKeysRegex, true, uniqPattern);

			if(originalAddr !== void 0 && originalAddr.rule !== void 0){
				originalAddr.template = Object.assign({}, template);
				const temp = originalAddr.template;
				delete temp.bindList;
			}
		}
		else{
			template = Object.create(originalAddr.template);

			// Deep Copy
			var parses = template.parse = template.parse.slice(0);
			for (let i = 0; i < parses.length; i++) {
				let ref = parses[i] = Object.assign({}, parses[i]);
				ref.data = Object.assign({}, ref.data);
				ref.data._modelScope = modelRef;
			}
		}

		template.bindList = this;

		if(devMode === true)
			template.rootIndex = $.getSelector(parentNode, true, sf(parentNode, true));

		if(this.constructor === RepeatedList){
			template.repeatedList = true;
			self.repeatedListBindRoot(template, modelRef);
		}
	}
	else if(element.hasAttribute('sf-as-scope'))
		EM.asScope = true;

	EM.template = isComponent || template;
	EM.list = this;
	EM.parentNode = parentNode;
	EM.modelRef = modelRef;
	EM.isComponent = !!isComponent;
	EM.namespace = namespace;
	EM.template.mask = mask;
	EM.elementRef = new WeakMap();
	EM.callback = callback; // Update callback
	parentNode.$EM = EM;

	// Check if this was nested repeated element
	if(originalAddr && modelKeysRegex.bindList !== void 0){
		template.parentTemplate = modelKeysRegex;
		var _list = modelKeysRegex.scopes._list;

		if(_list === void 0)
			_list = modelKeysRegex.scopes._list = [];

		if(modelKeysRegex.uniqPattern !== void 0)
			_list.push(modelKeysRegex.uniqPattern);

		if(modelKeysRegex.modelRef_regex_mask !== void 0)
			_list.push(modelKeysRegex.modelRef_regex_mask);

		template.scopes = modelKeysRegex.scopes;
		_list.regex = new RegExp(sfRegex.getScopeList.join(_list.join('|')), 'gm');

		originalAddr.template = template;
	}

	if(uniqPattern !== void 0)
		EM.template.uniqPattern = uniqPattern;

	const { nextSibling } = element;
	element.remove();

	// check if alone
	if(parentNode.childNodes.length <= 1 || parentNode.textContent.trim().length === 0)
		return true;

	const that = this;
	return function(){
		EM.bound_end = document.createComment('');
		parentNode.insertBefore(EM.bound_end, nextSibling);

		if(that.length !== void 0)
			EM.elements = new Array(that.length);
		if(that instanceof Map || that instanceof Set)
			EM.elements = new Array(that.size);
		else EM.elements = [];

		// Output to real DOM if not being used for virtual list
		injectArrayElements(EM, parentNode, EM.bound_end, that, modelRef, parentNode);
	}
}

// This will be called when constructing the
// Repeated (Property,Map,Set), but not for RepeatedList
function afterConstruct(modelRef, element, rule, parentNode, namespace){
	const { that } = rule;

	const alone = prepareRepeated.apply(that, arguments);
	const EM = that.$EM.constructor === ElementManipulatorProxy ? that.$EM.list[that.$EM.list.length-1] : that.$EM;

	if(alone === true){
		// Output to real DOM if not being used for virtual list
		EM.parentChilds = parentNode.children;

		injectArrayElements(EM, parentNode, void 0, that, modelRef, parentNode, namespace);
	}
	else alone();
}

function forceRefreshKeyData(list){
	if(list.$EM.constructor === ElementManipulatorProxy){
		list.$EM.list.forEach(refreshKeyFromEM);
		return;
	}

	refreshKeyFromEM(list.$EM);
}

function refreshKeyFromEM(EM){
	const {template, list} = EM;
	if(template.modelRef._sfkey_ === void 0) return;
	const elements = EM.elements || EM.parentChilds;

	if(list.constructor === RepeatedList){
		for (var i = 0; i < list.length; i++) {
			const temp = elements[i];
			temp.sf$repeatListIndex = i;
			syntheticTemplate(temp, template, '_sfkey_', list[i]);
		}
	}
	else{ // RepeatedMap
		var i = 0;
		for(const [key, val] of list){
			const temp = elements[i++];
			temp.sf$repeatListIndex = key;
			syntheticTemplate(temp, template, '_sfkey_', val);
		}
	}
}

class RepeatedProperty{ // extends Object
	static construct(modelRef, element, rule, parentNode, namespace, modelKeysRegex){
		const {that, target, prop, firstInit} = rule;

		// Initialize property once
		if(firstInit){
			hiddenProperty(that, '_list', Object.keys(that), true);

			Object.defineProperty(target, prop, {
				enumerable: true,
				configurable: true,
				get:()=> that,
				set:(val)=> {
					const olds = that._list;
					const news = Object.keys(val);

					// Assign if keys order was similar
					for (var a = 0; a < olds.length; a++) {
						if(olds[a] === news[a]){
							that[olds[a]] = val[olds[a]];
							continue;
						}
						break;
					}

					// Return if all new value has been assigned
					if(a === news.length && olds[a] === void 0)
						return;

					for (var i = a; i < olds.length; i++)
						sf.delete(that, olds[i]);

					for (var i = a; i < news.length; i++)
						sf.set(that, news[i], val[news[i]]);

					that._list = news;
				}
			});
		}

		afterConstruct.apply(this, arguments);

		// Proxy known property
		for(let key in that)
			ProxyProperty(that, key, true);
	}

	getElement(prop){
		if(prop == null)
			return; // undefined

		let { $EM } = this;
		if($EM.constructor === ElementManipulatorProxy)
			$EM = $EM.list[0];

		// If single RepeatedElement instance
		if(typeof this[prop] === 'object')
			return $EM.elementRef.get(this[prop]);
		return ($EM.parentChilds || $EM.elements)[this._list.indexOf(prop)];
	}

	// Return array
	getElements(index){
		if(this.$EM.constructor === ElementManipulatorProxy)
			return this.$EM.getElement_RP(this, index);

		return [this.getElement(index)];
	}

	refresh(){
		if(this.$EM.constructor === ElementManipulatorProxy)
			return this.$EM.refresh_RP(this);

		const elemList = (this.$EM.parentChilds || this.$EM.elements);
		if(elemList === void 0)
			return;

		// If single RepeatedElement instance
		const list = this._list;
		for (let i = 0; i < list.length; i++) {
			const elem = elemList[i];

			if(this[list[i]] !== elem.model){
				const newElem = this.$EM.createElement(list[i]);
				this.$EM.parentNode.replaceChild(newElem, elem);

				if(this.$EM.elements !== void 0)
					elemList[i] = newElem;
			}
		}
	}
}

class RepeatedMap extends Map{
	static construct(modelRef, element, rule, parentNode, namespace, modelKeysRegex){
		const {that, target, prop, firstInit} = rule;

		// Initialize property once
		if(firstInit){
			Object.defineProperty(target, prop, {
				enumerable: true,
				configurable: true,
				get:()=> that,
				set:(val)=> {
					// Delete first
					for(const [key, v] of that)
						!val.has(key) && that.delete(key);

					// Adding new item
					for(const [key, v] of val){
						if(that.get(key) === v) continue;
						that.set(key, v);
					}
				}
			});
		}

		afterConstruct.apply(this, arguments);
	}
	constructor(arg){return new Map(arg)}
	set(key, val){
		if(super.has(key)){
			const oldVal = super.get(key);
			if(oldVal === val) return this;
			this.$EM.remove(key, oldVal, true);
		}
		else if(this.$size !== void 0)
			this.$size();

		super.set.apply(this, arguments);
		this.$EM.append(key, val, true);
		return this;
	}
	clear(){
		super.clear();
		this.$EM.hardRefresh(0);

		if(this.$size !== void 0 && this.size !== 0)
			this.$size();
		return this;
	}
	delete(key){
		if(!this.has(key)) return this;

		const val = super.get(key);
		super.delete(key);
		this.$EM.remove(key, val, true);

		if(this.$size !== void 0) this.$size();
		return this;
	}
	refresh(){
		forceRefreshKeyData(this);
	}
}

class RepeatedSet extends Set{
	static construct(modelRef, element, rule, parentNode, namespace, modelKeysRegex){
		const {that, target, prop, firstInit} = rule;

		// Initialize property once
		if(firstInit){
			Object.defineProperty(target, prop, {
				enumerable: true,
				configurable: true,
				get:()=> that,
				set:(val)=> {
					// If an Set
					if(val.has !== void 0){
						for(const v of that) // Delete first
							!val.has(v) && that.delete(v);

						for(const v of val) // Adding new item
							!that.has(v) && that.add(v);

						return;
					}

					// If an Array
					for(const v of that) // Delete first
						!val.includes(v) && that.delete(v);

					for(var i = 0; i < val.length; i++) { // Adding new item
						const temp = val[i];
						!that.has(temp) && that.add(temp);
					}
				}
			});
		}

		afterConstruct.apply(this, arguments);
	}
	constructor(arg){return new Set(arg)}
	add(val){
		if(super.has(val)) return this;
		super.add(val);
		this.$EM.append(void 0, val,  false);

		if(this.$size !== void 0) this.$size();
		return this;
	}
	clear(){
		super.clear();
		this.$EM.hardRefresh(0);

		if(this.$size !== void 0 && this.size !== 0)
			this.$size();
		return this;
	}
	delete(val){
		if(!this.has(val)) return this;

		super.delete(val);
		this.$EM.remove(void 0, val, false);

		if(this.$size !== void 0) this.$size();
		return this;
	}
}

// Only for Object or RepeatedProperty
sf.set = function(obj, prop, val){
	if(obj[prop] === val)
		return;

	if(obj.$EM === void 0){
		obj[prop] = val;
		return;
	}

	if(obj[prop] === void 0){
		obj[prop] = val;
		ProxyProperty(obj, prop, false);

		obj.$EM.append(prop);
		obj._list.push(prop);
	}
}

sf.delete = function(obj, prop){
	if(obj.$EM === void 0){
		delete obj[prop];
		return;
	}

	const i = obj._list.indexOf(prop);
	if(i === -1)
		return;

	obj.$EM.remove(i);
	delete obj[prop];

	obj._list.splice(i, 1);
}

function ProxyProperty(obj, prop, force){
	if(force || Object.getOwnPropertyDescriptor(obj, prop).set === void 0){
		let temp = obj[prop];

		Object.defineProperty(obj, prop, {
			configurable:true,
			enumerable:true,
			get:()=> temp,
			set:(val)=> {
				temp = val;
				obj.refresh(prop);
			}
		});
	}
}

// This is called only once when RepeatedElement is initializing
// So we don't need to use cache
function injectArrayElements(EM, tempDOM, beforeChild, that, modelRef, parentNode, namespace){
	let temp, elem, scopes, { isComponent, template } = EM;

	// Has child repeated element
	const hasChild = template.parentTemplate !== void 0 || (template.specialElement && template.specialElement.repeat !== void 0);
	if(hasChild)
		scopes = template.scopes;

	if(that.constructor === RepeatedMap || that.constructor === RepeatedSet){
		const isMap = that instanceof Map;
		let i = -1;
		for(let item of that){
			i++;

			let key;
			if(isMap) [key, item] = item;

			if(hasChild){
				if(template.uniqPattern)
					scopes[template.uniqPattern] = key;

				scopes[template.modelRef_regex_mask] = item;
			}

			if(isComponent){
				if(isMap)
					item.$key = key;

				elem = new template(item, namespace, EM.asScope);
			}
			else elem = templateParser(template, item, false, modelRef, parentNode, void 0, key);

			if(typeof item === "object"){
				if(isComponent === false)
					self.bindElement(elem, modelRef, template, item);

				EM.elementRef.set(item, elem);
			}

			if(beforeChild === void 0)
				tempDOM.appendChild(elem);
			else{
				EM.elements[i] = elem;
				tempDOM.insertBefore(elem, beforeChild);
			}
		}
		return;
	}

	if(that.constructor === RepeatedProperty){
		temp = that;
		that = Object.values(that);
	}

	const len = that.length;
	for (var i = 0; i < len; i++) {
		const item = that[i];

		if(hasChild){
			if(template.uniqPattern)
				scopes[template.uniqPattern] = (temp === void 0 ? i : temp._list[i]);

			scopes[template.modelRef_regex_mask] = item;
		}

		if(isComponent)
			elem = new template(item, namespace, EM.asScope);
		else{
			elem = templateParser(template, item, false, modelRef, parentNode, void 0, template.uniqPattern && (temp === void 0 ? i : temp._list[i]));
		}

		if(typeof item === "object"){
			if(isComponent === false)
				self.bindElement(elem, modelRef, template, item);

			EM.elementRef.set(item, elem);
		}

		if(beforeChild === void 0)
			tempDOM.appendChild(elem);
		else if(beforeChild === true) // Virtual Scroll
			EM.elements[i] = elem;
		else{
			EM.elements[i] = elem;
			tempDOM.insertBefore(elem, beforeChild);
		}
	}

	// For RepeatedProperty
	if(temp !== void 0){
		var i = 0;
		for(let keys in temp)
			temp[keys] = that[i++];
	}
}

class RepeatedList extends Array{
	static construct(modelRef, element, rule, parentNode, namespace, modelKeysRegex){
		const {that, target, prop, firstInit} = rule;

		// Initialize property once
		if(firstInit){
			Object.defineProperty(target, prop, {
				enumerable: true,
				configurable: true,
				get:()=> that,
				set:(val)=> {
					if(val.length === 0)
						that.splice(0);
					else if(RE_Assign)
						that.assign(val);
					else that.remake(val, true);
				}
			});
		}

		const alone = prepareRepeated.apply(that, arguments);
		const EM = that.$EM.constructor === ElementManipulatorProxy ? that.$EM.list[that.$EM.list.length-1] : that.$EM;
		const { template } = EM;

		if(parentNode.classList.contains('sf-virtual-list')){
			hiddenProperty(that, '$virtual', new VirtualScroll(EM));

			if(alone !== true)
				console.warn("Virtual list was initialized when the container has another child that was not sf-repeated element.", parentNode);

			EM.elements = new Array(that.length);
			parentNode.$VSM = EM.$VSM = new VirtualScrollManipulator(parentNode, EM, template.html);

			// Put DOM element to the EM.elements only, and inject to the real DOM when ready
			injectArrayElements(EM, parentNode, true, that, modelRef, parentNode, namespace);

			EM.$VSM.startInjection();
			EM.$VSM.callbacks = target[`on$${prop}`];
		}
		else if(alone === true){
			// Output to real DOM if not being used for virtual list
			EM.parentChilds = parentNode.children;
			injectArrayElements(EM, parentNode, void 0, that, modelRef, parentNode, namespace);
		}
		else alone();

		// Wait for scroll plugin initialization
		setTimeout(function(){
			const scroller = internal.findScrollerElement(parentNode);
			if(scroller === null) return;

			internal.addScrollerStyle();

			const computed = getComputedStyle(scroller);
			if(computed.backfaceVisibility === 'hidden' || computed.overflow.includes('hidden'))
				return;

			scroller.classList.add('sf-scroll-element');
		}, 1000);
	}

	pop(){
		this.$EM.remove(this.length - 1);
		if(this.$length !== void 0) this.$length();
		return super.pop();
	}

	push(){
		const lastLength = this.length;
		super.push.apply(this, arguments);

		if(arguments.length === 1)
			this.$EM.append(lastLength);
		else this.$EM.hardRefresh(lastLength);

		if(this.$length !== void 0) this.$length();
		return this.length;
	}

	splice(index, limit, addition){
		if(index === 0 && limit === void 0){
			this.$EM.clear(0);
			return super.splice.apply(this, arguments);
		}

		const lastLength = this.length;

		if(lastLength === 0) index = 0;
		// Trim the index if more than length
		else if(arguments.length >= 3 && index >= lastLength)
			index = lastLength - 1;
		// Removing data
		else if(index < 0) index = lastLength + index;

		if(!limit && limit !== 0) limit = this.length;

		for (var i = limit - 1; i >= 0; i--)
			this.$EM.remove(index + i);

		const ret = super.splice.apply(this, arguments);
		if(arguments.length >= 3){ // Inserting data
			limit = arguments.length - 2;

			for (var i = 0; i < limit; i++)
				this.$EM.insertAfter(index + i);
		}

		if(this.$length !== void 0) this.$length();
		return ret;
	}

	shift(){
		const ret = super.shift();
		this.$EM.remove(0);

		if(this.$length !== void 0) this.$length();
		return ret;
	}

	unshift(){
		super.unshift.apply(this, arguments);

		if(arguments.length === 1)
			this.$EM.prepend(0);
		else for (let i = arguments.length - 1; i >= 0; i--)
			this.$EM.prepend(i);

		if(this.$length !== void 0) this.$length();
		return this.length;
	}

	constructor(arr){return new Array(arr)}
	assign(fromIndex, withArray, removes, putLast){
		if(fromIndex.constructor !== Number){
			if(removes === void 0 || removes.constructor === Boolean)
				putLast = removes; // true=last index, false=first, undefined=depends

			if(withArray !== void 0 && withArray.constructor === Object)
				removes = withArray;

			withArray = fromIndex;
			fromIndex = 0;
		}

		if(withArray.constructor !== Array)
			withArray = [withArray];

		if(removes !== void 0){
			if(removes.constructor === Object){
				const temp = {};

				for(let key in removes){
					if(key.slice(-1) === ']'){
						const k = key.split('[');
						switch(k[1]){
							case "!]":
							if(temp.b === void 0) temp.b = [];
							temp.b.push({key:key[0], val:removes[key]});
							break;
							case "<]":
							if(temp.c === void 0) temp.c = [];
							temp.c.push({key:key[0], val:removes[key]});
							break;
							case "<=]":
							if(temp.d === void 0) temp.d = [];
							temp.d.push({key:key[0], val:removes[key]});
							break;
							case ">]":
							if(temp.e === void 0) temp.e = [];
							temp.e.push({key:key[0], val:removes[key]});
							break;
							case ">=]":
							if(temp.f === void 0) temp.f = [];
							temp.f.push({key:key[0], val:removes[key]});
							break;
							default:
							if(temp.a === void 0) temp.a = [];
							temp.a.push({key:key[0], val:removes[key]});
							break;
						}
					}
					else{
						if(temp.a === void 0) temp.a = [];
						temp.a.push({key:key[0], val:removes[key]});
					}
				}

				removes = temp;
			}

			let processed;
			if(putLast === true)
				processed = new Set();

			that:for(var i = fromIndex; i < this.length; i++){
				if(putLast === true && processed.has(this[i]))
					break;

				if(removes.constructor === Object){
					const temp1 = this[i];
					if(removes.a !== void 0){ // ===
						for(var z=0, n=removes.a.length; z < n; z++){
							var temp2 = removes.a[z];
							if(temp1[temp2.key] !== temp2.val)
								continue that;
						}
					}
					if(removes.b !== void 0){ // !==
						for(var z=0, n=removes.b.length; z < n; z++){
							var temp2 = removes.b[z];
							if(temp1[temp2.key] === temp2.val)
								continue that;
						}
					}
					if(removes.c !== void 0){ // <
						for(var z=0, n=removes.c.length; z < n; z++){
							var temp2 = removes.c[z];
							if(temp1[temp2.key] >= temp2.val)
								continue that;
						}
					}
					if(removes.d !== void 0){ // <=
						for(var z=0, n=removes.d.length; z < n; z++){
							var temp2 = removes.d[z];
							if(temp1[temp2.key] > temp2.val)
								continue that;
						}
					}
					if(removes.e !== void 0){ // >
						for(var z=0, n=removes.e.length; z < n; z++){
							var temp2 = removes.e[z];
							if(temp1[temp2.key] <= temp2.val)
								continue that;
						}
					}
					if(removes.f !== void 0){ // >=
						for(var z=0, n=removes.f.length; z < n; z++){
							var temp2 = removes.f[z];
							if(temp1[temp2.key] < temp2.val)
								continue that;
						}
					}
				}
				else if(!removes(this[i]))
					continue;

				if(withArray.length === 0){
					this.splice(i--, 1);
					continue;
				}

				const current = withArray.shift();
				if(this[i] !== current)
					Object.assign(this[i], current);

				if(putLast === true){
					processed.add(this[i]);
					this.push(this.splice(i--, 1)[0]);
				}
				else if(putLast === false)
					this.unshift(this.splice(i, 1)[0]);
			}

			if(withArray.length !== 0){
				if(putLast === false)
					this.unshift(...withArray);
				else
					this.push(...withArray);
			}

			return this;
		}
		else{
			for(var i = 0; i < withArray.length; i++){
				if(i === this.length)
					break;

				const old = this[i + fromIndex], now = withArray[i];
				if(old !== now){
					let oldStatus = RE_Assign;
					RE_Assign = true;

					Object.assign(old, now);
					RE_Assign = oldStatus;
				}
			}
		}

		if(withArray.length === this.length || fromIndex !== 0)
			return this;

		const lastLength = this.length;
		if(withArray.length > this.length){
			super.push(...withArray.slice(this.length));
			this.$EM.hardRefresh(lastLength);
		}
		else{
			super.splice(withArray.length);
			this.$EM.removeRange(withArray.length, lastLength);
		}

		if(this.$length !== void 0) this.$length();
		return this;
	}

	remake(newList, atMiddle){
		const lastLength = this.length;

		// Check if item has same reference
		if(newList.length >= lastLength && lastLength !== 0){
			let matchLeft = lastLength;

			for (var i = 0; i < lastLength; i++) {
				if(newList[i] === this[i]){
					matchLeft--;
					continue;
				}
				break;
			}

			// Add new element at the end
			if(matchLeft === 0){
				if(newList.length === lastLength) return;
				this.splice(lastLength, 0, ...newList.slice(lastLength));

				if(this.$length !== void 0) this.$length();
				return;
			}

			// Add new element at the middle
			else if(matchLeft !== lastLength){
				if(atMiddle === true){
					super.splice(i, lastLength - i, ...newList.slice(i));
					this.refresh(i, lastLength);

					if(this.$length !== void 0) this.$length();
				}
				return;
			}
		}

		// Build from zero
		if(lastLength === 0){
			super.push(...newList);
			this.$EM.hardRefresh(0);

			if(this.$length !== void 0) this.$length();
			return;
		}

		// Clear all items and merge the new one
		super.splice(0, lastLength, ...newList);

		// Rebuild all element
		if(atMiddle !== true){
			this.$EM.clear(0);
			this.$EM.hardRefresh(0);
		}

		// Reuse some element
		else{
			// Clear unused element if current array < last array
			if(this.length < lastLength)
				this.$EM.removeRange(this.length, lastLength);

			// And start refreshing
			this.$EM.hardRefresh(0, this.length);
		}

		if(this.$length !== void 0) this.$length();
		return this;
	}

	swap(i, o){
		if(i === o) return;
		this.$EM.swap(i, o);
		const temp = this[i];
		this[i] = this[o];
		this[o] = temp;
	}

	move(from, to, count){
		if(from === to) return;
		if(count === void 0) count = 1;

		this.$EM.move(from, to, count);
		super.splice(to, 0, ...super.splice(from, count));
	}

	// Return single element from first $EM
	getElement(index){
		if(index == null)
			return; // undefined

		let { $EM } = this;
		if($EM.constructor === ElementManipulatorProxy)
			$EM = $EM.list[0];

		// If single RepeatedElement instance
		if(index.constructor === Number){
			if(typeof this[index] !== 'object')
				return ($EM.parentChilds || $EM.elements)[index];

			return $EM.elementRef.get(this[index]);
		}

		return $EM.elementRef.get(index);
	}

	// Return array
	getElements(index){
		if(this.$EM.constructor === ElementManipulatorProxy)
			return this.$EM.getElement_RL(this, index);

		return [this.getElement(index)];
	}

	indexOf(item){
		if(item != null && item.children !== void 0 && item.children.constructor === HTMLCollection){
			if(!item.sf$elementReferences || !item.sf$elementReferences.template.bindList)
				item = findBindListElement(item);

			if(item === null)
				return -1;

			arguments[0] = item.model;
		}

		return super.indexOf.apply(this, arguments);
	}

	reverse(){
		this.$EM.reverse();
		super.reverse();
	}

	refresh(index, length){
		if(index === void 0 || index.constructor === String){
			index = 0;
			({ length } = this);
		}
		else if(length === void 0) length = index + 1;
		else if(length < 0) length = this.length + length;
		else length += index;

		// Trim length
		const overflow = this.length - length;
		if(overflow < 0) length = length + overflow;

		if(this.$EM.constructor === ElementManipulatorProxy)
			var elems = this.$EM.list[0].parentChilds || this.$EM.list[0].elements;
		else
			var elems = this.$EM.parentChilds || this.$EM.elements;

		for (let i = index; i < length; i++) {
			// Create element if not exist
			if(elems[i] === void 0){
				this.$EM.hardRefresh(i);
				return;
			}

			if(this.$EM.constructor === ElementManipulatorProxy)
				var oldElem = this.$EM.list[0].elementRef.get(this[i]);
			else
				var oldElem = this.$EM.elementRef.get(this[i]);

			if(oldElem === void 0 || elems[i].model !== oldElem.model)
				this.$EM.update(i, 1);
		}

		forceRefreshKeyData(this);
	}
}

class ElementManipulator{
	createElement(index, item, isMap){
		if(isMap === void 0) // array
			item = this.list[index];

		if(item === void 0) return;

		const { template } = this;
		let temp = this.elementRef && this.elementRef.get(item);

		if(temp !== void 0){
			if(temp.model.$el === void 0){
				// This is not a component, lets check if all property are equal
				if(compareObject(temp.model, item) === false){
					temp = templateParser(template, item, false, this.modelRef, this.parentNode, void 0, template.uniqPattern && index);

					if(typeof item === "object"){
						if(this.isComponent === false)
							self.bindElement(temp, this.modelRef, template, item);

						if(this.elementRef !== void 0)
							this.elementRef.set(item, temp);
					}
				}
				else if(temp.sf$bindedBackup !== void 0){
					RE_restoreBindedList(this.modelRef, temp.sf$bindedBackup);
					temp.sf$bindedBackup = void 0;
				}

				if(template.modelRef._sfkey_ !== void 0){
					temp.sf$repeatListIndex = index;
					syntheticTemplate(temp, template, '_sfkey_', item);
				}
			}

			if(this.$VSM) this.$VSM.newElementInit(temp, index-1);
			return temp;
		}

		if(template.constructor === Function)
			temp = new template(item, this.namespace, this.asScope);
		else temp = templateParser(template, item, false, this.modelRef, this.parentNode, void 0, template.uniqPattern && index);

		if(typeof item === "object"){
			if(this.isComponent === false)
				self.bindElement(temp, this.modelRef, template, item);

			if(this.elementRef !== void 0)
				this.elementRef.set(item, temp);
		}

		if(this.$VSM) this.$VSM.newElementInit(temp, index-1);
		return temp;
	}

	// Recreate the item element after the index
	hardRefresh(index){
		const { list } = this;
		const exist = this.parentChilds || this.elements;

		if(this.template.modelRefRoot_path && this.template.modelRefRoot_path.length !== 0)
			this.clearBinding(exist, index);

		if(index === 0 && this.$VSM === void 0 && this.bound_end === void 0)
			this.parentNode.textContent = '';
		else{
			// Clear siblings after the index
			if(this.parentChilds){
				for (var i = index, n = exist.length; i < n; i++) {
					exist[index].remove();
				}
			}
			else for (var i = index; i < exist.length; i++) {
				exist[i].remove();
			}

			if(this.elements !== void 0)
				exist.length = index;
		}

		if(this.elements !== void 0)
			exist.length = list.length || 0;

		for (var i = index; i < list.length; i++) {
			const ref = list[i];
			let temp = this.elementRef.get(ref);

			if(temp === void 0){
				if(this.isComponent)
					temp = new this.template(ref, this.namespace, this.asScope);
				else
					temp = templateParser(this.template, ref, false, this.modelRef, this.parentNode, void 0, this.template.uniqPattern && i);

				if(typeof ref === "object"){
					if(this.isComponent === false)
						self.bindElement(temp, this.modelRef, this.template, ref);

					this.elementRef.set(ref, temp);

					if(this.elements !== void 0)
						exist[i] = temp;
				}
			}
			else if(temp.model.$el === void 0){
				// This is not a component, lets check if all property are equal
				if(compareObject(temp.model, ref) === false){
					temp = templateParser(this.template, ref, false, this.modelRef, this.parentNode, void 0, this.template.uniqPattern && i);

					if(typeof ref === "object"){
						if(this.isComponent === false)
							self.bindElement(temp, this.modelRef, this.template, ref);

						this.elementRef.set(ref, temp);

						if(this.elements !== void 0)
							exist[i] = temp;
					}
				}
				else if(temp.sf$bindedBackup !== void 0){
					RE_restoreBindedList(this.modelRef, temp.sf$bindedBackup);
					temp.sf$bindedBackup = void 0;
				}

				if(this.template.modelRef._sfkey_ !== void 0){
					temp.sf$repeatListIndex = i;
					syntheticTemplate(temp, this.template, '_sfkey_', ref);
				}
			}

			if(this.$VSM === void 0)
				this.parentNode.appendChild(temp);
			else{
				exist[i] = temp;
				this.$VSM.newElementInit(temp, i-1);
			}
		}

		if(this.$VSM) this.$VSM.hardRefresh(index);
	}

	update(index, other){
		const exist = this.parentChilds || this.elements;
		const { list } = this;
		const { template } = this;

		if(index === void 0){
			index = 0;
			other = list.length;
		}
		else if(other === void 0) other = index + 1;
		else if(other < 0) other = list.length + other;
		else other += index;

		// Trim length
		const overflow = list.length - other;
		if(overflow < 0) other = other + overflow;

		if(this.template.modelRefRoot_path && this.template.modelRefRoot_path.length !== 0)
			this.clearBinding(exist, index, other);

		for (let i = index; i < other; i++) {
			const oldChild = exist[i];
			if(oldChild === void 0 || list[i] === void 0)
				break;

			const ref = list[i];
			let temp = this.elementRef.get(ref);

			if(temp === void 0){
				if(this.isComponent)
					temp = new template(ref, this.namespace, this.asScope);
				else
					temp = templateParser(template, ref, false, this.modelRef, this.parentNode, void 0, template.uniqPattern && i);

				if(typeof ref === "object"){
					if(this.isComponent === false)
						self.bindElement(temp, this.modelRef, template, ref);

					this.elementRef.set(ref, temp);

					if(this.elements != void 0)
						exist[i] = temp;
				}
			}
			else if(temp.model.$el === void 0){
				// This is not a component, lets check if all property are equal
				if(compareObject(temp.model, ref) === false){
					temp = templateParser(template, ref, false, this.modelRef, this.parentNode, void 0, template.uniqPattern && i);

					if(typeof ref === "object"){
						if(this.isComponent === false)
							self.bindElement(temp, this.modelRef, template, ref);

						this.elementRef.set(ref, temp);

						if(this.elements != void 0)
							exist[i] = temp;
					}
				}
				else if(temp.sf$bindedBackup !== void 0){
					RE_restoreBindedList(this.modelRef, temp.sf$bindedBackup);
					temp.sf$bindedBackup = void 0;
				}

				if(this.template.modelRef._sfkey_ !== void 0){
					temp.sf$repeatListIndex = i;
					syntheticTemplate(temp, this.template, '_sfkey_', ref);
				}
			}

			if(this.$VSM){
				this.$VSM.newElementInit(temp, i-1);
				this.$VSM.update(i, temp);
				continue;
			}

			this.parentNode.replaceChild(temp, oldChild);

			if(this.elements != void 0)
				exist[i] = temp;

			if(this.callback.update)
				this.callback.update(temp, 'replace');
		}
	}

	move(from, to, count){
		const exist = this.parentChilds || this.elements;

		const overflow = this.list.length - from - count;
		if(overflow < 0)
			count += overflow;

		const vDOM = new Array(count);
		for (var i = 0; i < count; i++)
			(vDOM[i] = exist[from + i]).remove();

		if(this.$VSM === void 0){
			const nextSibling = exist[to] || null;

			// Move to defined index
			for (var i = 0; i < count; i++) {
				this.parentNode.insertBefore(vDOM[i], nextSibling);

				if(this.callback.update)
					this.callback.update(vDOM[i], 'move');
			}
		}

		if(this.elements !== void 0){
			exist.splice(from, count);
			exist.splice(to, 0, ...vDOM);

			if(this.$VSM !== void 0)
				this.$VSM.move(from, to, count, vDOM);
		}
	}

	swap(index, other){
		const exist = this.parentChilds || this.elements;

		const ii=index, oo=other;
		if(index > other){
			const index_a = exist[other];
			other = exist[index];
			index = index_a;
		} else {
			index = exist[index];
			other = exist[other];
		}

		if(this.elements !== void 0){
			const temp = exist[ii];
			exist[ii] = exist[oo];
			exist[oo] = temp;
		}

		if(this.$VSM === void 0){
			const other_sibling = other.nextSibling;
			const other_parent = other.parentNode;
			index.parentNode.insertBefore(other, index.nextSibling);
			other_parent.insertBefore(index, other_sibling);
		}
		else this.$VSM.swap(ii, oo);

		if(this.callback.update){
			this.callback.update(exist[other], 'swap');
			this.callback.update(exist[index], 'swap');
		}
	}

	remove(index, item, isMap){
		const exist = this.parentChilds || this.elements;
		if(isMap !== void 0){
			let key = isMap === true ? index : void 0;
			for (index = 0; index < exist.length; index++) {
				const el = exist[index];
				if(el.model === item && (key === void 0 || el.sf$repeatListIndex === key))
					break;
			}
		}

		if(this.template.modelRefRoot_path && this.template.modelRefRoot_path.length !== 0)
			this.clearBinding(exist, index, index+1);

		if(exist[index]){
			if(this.callback.remove){
				if(this.elements !== void 0)
					var currentEl = exist[index];
				else{
					// This for fix bug when some element are pending to be deleted
					if(isMap === void 0)
						item = this.list[index];

					for (var i = 0, n=exist.length; i < n; i++)
						if(exist[i].model === item) break;

					var currentEl = exist[i];
				}

				let currentRemoved = false;
				const startRemove = function(){
					if(currentRemoved) return;
					currentRemoved = true;

					currentEl.remove();
				};

				// Instant remove if return falsy value
				if(!this.callback.remove(currentEl, startRemove))
					startRemove();
			}
			// Instant remove if no callback
			else exist[index].remove();

			if(this.$VSM) this.$VSM.remove(index);

			if(this.elements !== void 0)
				exist.splice(index, 1);
		}
	}

	removeRange(index, other){
		const exist = this.parentChilds || this.elements;

		for (let i = index; i < other; i++)
			exist[index].remove();

		if(this.template.modelRefRoot_path && this.template.modelRefRoot_path.length !== 0)
			this.clearBinding(exist, index, other);

		if(this.$VSM)
			this.$VSM.removeRange(index, other);
		else if(this.elements !== void 0)
			exist.splice(index, other-index);
	}

	clear(){
		if(this.template.modelRefRoot_path && this.template.modelRefRoot_path.length !== 0)
			this.clearBinding(this.parentChilds || this.elements, 0);

		this.parentNode.textContent = '';

		if(this.$VSM !== void 0)
			this.$VSM.clear();

		if(this.elements !== void 0)
			this.elements.length = 0;
	}

	insertAfter(index){
		const exist = this.parentChilds || this.elements;
		const temp = this.createElement(index);

		if(this.$VSM === void 0){
			if(exist.length === 0)
				this.parentNode.insertBefore(temp, this.parentNode.lastElementChild);
			else{
				const referenceNode = exist[index-1];
				referenceNode.parentNode.insertBefore(temp, referenceNode.nextSibling);
			}
		}

		if(this.elements !== void 0)
			exist.splice(index, 0, temp);

		if(this.$VSM) this.$VSM.insertAfter(index);

		if(this.callback.create)
			this.callback.create(temp);
	}

	prepend(index){
		const exist = this.parentChilds || this.elements;
		const temp = this.createElement(index);

		if(this.$VSM === void 0){
			const referenceNode = exist[0];
			if(referenceNode !== void 0){
				referenceNode.parentNode.insertBefore(temp, referenceNode);

				if(this.callback.create)
					this.callback.create(temp);
			}
			else this.parentNode.insertBefore(temp, this.parentNode.lastElementChild);
		}

		if(this.elements !== void 0)
			exist.unshift(temp);

		if(this.$VSM) this.$VSM.prepend(index);
	}

	append(index, item, isMap){
		const exist = this.parentChilds || this.elements;
		const temp = this.createElement(index, item, isMap);

		if(this.elements !== void 0)
			exist.push(temp);

		if(this.$VSM === void 0){
			if(this.bound_end !== void 0)
				this.parentNode.insertBefore(temp, this.bound_end);
			else
				this.parentNode.appendChild(temp);
		}
		else this.$VSM.append(index);

		if(this.callback.create)
			this.callback.create(temp);
	}

	reverse(){
		if(this.parentChilds !== void 0){
			const len = this.parentChilds.length;
			if(len === 0)
				return;

			const beforeChild = this.parentChilds[0];
			for (var i = 1; i < len; i++) {
				this.parentNode.insertBefore(this.parentNode.lastElementChild, beforeChild);
			}
		}
		else{
			const elems = this.elements;
			elems.reverse();

			if(this.$VSM)
				return this.$VSM.reverse();

			if(this.bound_end === void 0)
				for (var i = 0; i < elems.length; i++)
					this.parentNode.appendChild(elems[i]);
			else
				for (var i = 0; i < elems.length; i++)
					this.parentNode.insertBefore(elems[i], this.bound_end);
		}
	}

	clearBinding(elemList, from, to){
		if(to === void 0)
			to = this.list.length;

		const modelRoot = this.modelRef;
		const binded = this.template.modelRefRoot_path;

		if(elemList.constructor !== Array){
			// Loop for every element between range first (important)
			for (var i = from; i < to; i++) {
				var elem = elemList.item(i);

				// Loop for any related property
				for (var a = binded.length-1; a >= 0; a--) {
					var bindList = RE_getBindedList(modelRoot, binded[a]);
					if(bindList === void 0)
						continue;

					for (var z = bindList.length-1; z >= 0; z--) {
						if(bindList[z].element === elem){
							if(elem.sf$bindedBackup === void 0)
								elem.sf$bindedBackup = [];

							elem.sf$bindedBackup.push([binded[a], bindList.splice(z, 1)[0]]);
						}
					}
				}
			}
			return;
		}

		// Loop for any related property
		for (var a = binded.length-1; a >= 0; a--) {
			var bindList = RE_getBindedList(modelRoot, binded[a]);
			if(bindList === void 0)
				continue;

			for (var z = bindList.length-1; z >= 0; z--) {
				var i = elemList.indexOf(bindList[z].element);

				// Is between range?
				if(i === -1 || i < from ||  i >= to)
					continue;

				var elem = bindList[z].element;
				if(elem.sf$bindedBackup === void 0)
					elem.sf$bindedBackup = [];

				elem.sf$bindedBackup.push([binded[a], bindList.splice(z, 1)[0]]);
			}
		}
	}
}

class ElementManipulatorProxy{
	refresh_RP(instance){
		const { list } = this;
		const keys = instance._list;
		for (let i = 0; i < list.length; i++) {
			const EM = list[i];
			const elemList = (EM.parentChilds || EM.elements);

			if(elemList === void 0)
				continue;

			for (let a = 0; a < keys.length; a++) {
				const elem = elemList[a];

				if(elem === void 0){
					EM.append(keys[a]);
					continue;
				}

				if(instance[keys[a]] !== elem.model){
					const newElem = EM.createElement(keys[a]);
					EM.parentNode.replaceChild(newElem, elem);

					if(EM.elements !== void 0)
						elemList[a] = newElem;
				}
			}
		}
	}
	getElement_RP(instance, prop){
		if(prop == null)
			return [];

		const { list } = this;
		const keys = instance._list;

		const got = [];
		for (let i = 0; i < list.length; i++) {
			let val;
			if(typeof this[prop] === 'object')
				val = list[i].elementRef.get(instance[prop]);
			else
				val = (list[i].parentChilds || list[i].elements)[keys.indexOf(prop)];

			if(val)
				got.push(val);
		}
		return got;
	}
	getElement_RL(instance, index){
		if(index == null)
			return [];

		const { list } = this;
		const got = [];

		for (let i = 0; i < list.length; i++) {
			const EM = list[i];
			let val;

			if(index.constructor === Number){
				if(typeof instance[index] !== 'object')
					val = (EM.parentChilds || EM.elements)[index];
				else
					val = EM.elementRef.get(instance[index]);
			}
			else val = EM.elementRef.get(index);

			if(val)
				got.push(val);
		}

		return got;
	}

	$el(selector){
		const list = [];
		const $EMs = this.list;
		for (let i = 0; i < $EMs.length; i++) {
			const em = $EMs[i];
			list.push(...queryElements((em.parentChilds || em.elements), selector));
		}
		return $(list);
	}

	hardRefresh(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.hardRefresh.apply(list[i], arguments);
	}
	update(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.update.apply(list[i], arguments);
	}
	move(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.move.apply(list[i], arguments);
	}
	swap(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.swap.apply(list[i], arguments);
	}
	remove(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.remove.apply(list[i], arguments);
	}
	removeRange(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.removeRange.apply(list[i], arguments);
	}
	clear(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.clear.apply(list[i], arguments);
	}
	insertAfter(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.insertAfter.apply(list[i], arguments);
	}
	prepend(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.prepend.apply(list[i], arguments);
	}
	append(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.append.apply(list[i], arguments);
	}
	reverse(){
		const { list } = this;
		for (let i = 0; i < list.length; i++)
			EM_Proto.reverse.apply(list[i], arguments);
	}
}

var EM_Proto = ElementManipulator.prototype;
internal.EM = ElementManipulator;
internal.EMP = ElementManipulatorProxy;

function RE_restoreBindedList(modelRoot, lists){
	// lists [paths, backup]
	for (let i = 0; i < lists.length; i++) {
		const bindList = RE_getBindedList(modelRoot, lists[i][0]);
		if(bindList === void 0)
			continue;

		bindList.push(lists[i][1]);
	}
}

// return sf$bindedKey or undefined
function RE_getBindedList(modelRoot, binded){
	if(binded.length === 1)
		return modelRoot.sf$bindedKey[binded[0]];

	const check = deepProperty(modelRoot, binded.slice(0, -1));
	if(check === void 0 || check.sf$bindedKey === void 0)
		return;

	return check.sf$bindedKey[binded[binded.length - 1]];
}

;{
	const RE_Prototype = {
		// For RepeatedProperty, RepeatedList, RepeatedMap, RepeatedSet
		$el:{
			value:(selector)=> {
				const { $EM } = this;
				if($EM.constructor === ElementManipulatorProxy)
					return $EM.$el(selector)
				return $(queryElements(($EM.parentChilds || $EM.elements), selector));
			}
		},
	};

	const d = Object.defineProperties;
	d(RepeatedProperty.prototype, RE_Prototype);
	d(RepeatedList.prototype, RE_Prototype);
	d(RepeatedMap.prototype, RE_Prototype);
	d(RepeatedSet.prototype, RE_Prototype);
};